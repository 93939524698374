import React, { ChangeEvent, useState, FormEvent } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '120px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function MainLoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // eslint-disable-next-line
  const [user, setUser] = useState<{ displayName: undefined; photoURL: undefined; passcode?: string } | null>(null);

  const handleChangeUsername = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const login = () => {
    let displayName = username;
    setUser({ displayName } as any);
    window.sessionStorage.setItem('displayName', displayName);
    history.replace(location?.state?.from || { pathname: '/room/1' });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  return (
    <IntroContainer>
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Please login to your account
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="space-between" className={classes.gutterBottom}>
            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-username">
                Username
              </InputLabel>
              <TextField
                id="input-username"
                type="text"
                variant="outlined"
                size="small"
                value={username}
                onChange={handleChangeUsername}
              />
              <InputLabel shrink htmlFor="input-password">
                Password
              </InputLabel>
              <TextField
                id="input-password"
                type="password"
                variant="outlined"
                size="small"
                value={password}
                onChange={handleChangePassword}
              />
            </div>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!username.length && !password.length}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid>
        </form>
      </>
    </IntroContainer>
  );
}
