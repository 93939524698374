import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import ChatWindow from '../ChatWindow/ChatWindow';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import usePublications from '../../hooks/usePublications/usePublications';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth}px`;
  return {
    container: {
      position: 'relative',
      height: '80%',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    chatWindowOpen: { gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.chatWindowWidth}px` },
  };
});

export default function Room() {
  const mainParticipant = useMainParticipant();
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  const publications = usePublications(mainParticipant);
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));
  const [height, setHeight] = useState('100%');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < window.innerHeight) {
        if (screenSharePublication) {
          setHeight('80%');
        }
      } else {
        setHeight('100%');
      }
    }
    window.addEventListener('resize', handleResize);
  });
  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      if (screenSharePublication) {
        setHeight('80%');
      } else {
        setHeight('100%');
      }
    }
  }, [screenSharePublication]);
  return (
    <div className={clsx(classes.container, { [classes.chatWindowOpen]: isChatWindowOpen })} style={{ height: height }}>
      <MainParticipant />
      <ParticipantList />
      <ChatWindow />
    </div>
  );
}
