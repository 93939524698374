import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import DeviceSettingButton from '../PreJoinScreens/DeviceSelectionScreen/DeviceSettingButton';
import { isMobile } from '../../utils';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
// import SettingsMenu from '../PreJoinScreens/DeviceSelectionScreen/SettingsMenu/SettingsMenu';
import Record from './Menu/Record';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    endcallGrid: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        marginBottom: '2em',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  // const { room } = useVideoContext();
  const isDoctor = window.sessionStorage.getItem('isDoctor') + '';

  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">画面を共有しています。</Typography>
          <Button onClick={() => toggleScreenShare()}>共有を停止</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>{/* <Typography variant="body1">{room!.name}</Typography> */}</Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              {/* <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />*/}
              {isDoctor === '1' && <Record />}
              <Hidden smDown>
                {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
              </Hidden>
              <DeviceSettingButton />

              {/* {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />} */}
            </Grid>
          </Grid>
          <Grid style={{ flex: 1 }}>
            <Grid container className={classes.endcallGrid}>
              {/* <Menu /> */}
              <EndCallButton />
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
