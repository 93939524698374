import React from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { DialogContent, Divider, Dialog, Button, Theme, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '380px',
    minHeight: '100px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
    alignItems: 'center',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  yesButton: {
    fontSize: '24px',
    margin: '5px',
    backgroundColor: '#1e3c96',
    color: '#fbe9f3',
    minWidth: '155px',
    minHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
      fontSize: '24px',
      width: '275px',
    },
    '&:hover': {
      background: '#1E3C96',
    },
  },
  NoButton: {
    fontSize: '24px',
    margin: '5px',
    backgroundColor: 'red',
    color: '#fbe9f3',
    minWidth: '155px',
    minHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
      fontSize: '24px',
      width: '275px',
    },
    '&:hover': {
      background: 'red',
    },
  },
}));

export default function EndCallDIalog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const DisconnectRequest = () => {
    room!.disconnect();
    history.replace(location?.state?.from || { pathname: '/EndCall' });
  };
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Grid container justifyContent="center">
          オンライン診療を終了しますか？
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.container}>
        <Grid container justifyContent="center">
          <Button className={classes.yesButton} onClick={DisconnectRequest}>
            はい
          </Button>
          <Button className={classes.NoButton} onClick={onClose}>
            いいえ
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
