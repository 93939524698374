import React from 'react';

import { Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    paddingTop: '45vh',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '35vh',
    },
  },
}));

export default function WrongRoom() {
  const classes = useStyles();

  return (
    <IntroContainer>
      <>
        <Typography variant="h3" className={classes.gutterBottom}>
          Wrong room id
        </Typography>
      </>
    </IntroContainer>
  );
}
