import React from 'react';

import { Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    paddingTop: '45vh',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '35vh',
    },
  },
}));

export default function EndPayment() {
  const classes = useStyles();
  const { Code } = useParams();
  var message = '';
  if (Code === '1') message = '決済が完了しました。';
  else if (Code === '0') message = '決済が完了できませんでした。決済から再実行してください。';
  return (
    <IntroContainer>
      <>
        <Typography variant="h4" className={classes.gutterBottom}>
          {message}
        </Typography>
      </>
    </IntroContainer>
  );
}
